@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'opendyslexic';
  src: url('/fonts/OpenDyslexic-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'opendyslexic';
  src: url('/fonts/OpenDyslexic-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'opendyslexic';
  src: url('/fonts/OpenDyslexic-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'opendyslexic';
  src: url('/fonts/OpenDyslexic-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

html {
  @apply scroll-pt-[70px] lg:scroll-pt-[140px];
}

html,
body,
#_next {
  padding: 0;
  margin: 0;
  height: -webkit-fill-available;
}

body.opendyslexic {
  @apply font-opendyslexic !important;
}

html.large-font {
  font-size: 18px;
}
html.extra-large-font {
  font-size: 20px;
}

body.high-contrast-mode {
  @apply bg-contrast-yellow text-black !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@layer components {
  .btn {
    @apply flex justify-center text-center items-center;
    @apply cursor-pointer disabled:cursor-not-allowed disabled:opacity-75;
    @apply btn-md; /* button size is 'md' by default */
    @apply transition-all;
  }

  /* button sizes */
  .btn-xs {
    @apply border rounded-sm py-2 px-3;
    @apply font-bold text-xs leading-4;
  }
  .btn-sm {
    @apply border rounded-sm py-2 px-3;
    @apply font-bold text-sm leading-4;
  }
  .btn-md {
    @apply border rounded-sm py-2 px-4;
    @apply font-bold text-sm leading-5;
  }
  .btn-lg {
    @apply border rounded-sm py-2 px-4;
    @apply font-bold text-base leading-6;
  }
  .btn-xl {
    @apply border rounded-sm py-3 px-6;
    @apply font-bold text-base leading-6;
  }

  /* button styles */
  .btn-primary {
    @apply border-primary-500 bg-primary-500 text-white;
    @apply hover:border-primary-700 hover:bg-primary-700;
    @apply disabled:hover:bg-primary-500 disabled:hover:border-primary-500;
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-primary-500;
  }
  .btn-primary-alt {
    @apply border-primary-alt-500 bg-primary-alt-500 text-white;
    @apply hover:border-primary-alt-700 hover:bg-primary-alt-700;
    @apply disabled:hover:bg-primary-alt-500 disabled:hover:border-primary-alt-500;
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-primary-alt-500;
  }
  .btn-secondary {
    @apply border-secondary-500 bg-secondary-500 text-white;
    @apply hover:border-secondary-700 hover:bg-secondary-700;
    @apply disabled:hover:bg-secondary-500 disabled:hover:border-secondary-500;
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-secondary-500;
  }
  .btn-outline {
    @apply border-gray-300 bg-white text-gray-700;
    @apply hover:border-gray-300 hover:bg-gray-50;
    @apply disabled:hover:bg-white disabled:hover:border-gray-300;
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-primary-500;
  }
  .btn-contrast-yellow {
    @apply border-black bg-contrast-yellow text-black;
    @apply hover:border-black hover:bg-black hover:text-contrast-yellow;
    @apply disabled:hover:bg-contrast-yellow disabled:hover:border-black;
    @apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black;
  }

  /* button utils */
  .btn-full {
    @apply w-full;
  }
  .btn-rounded {
    @apply rounded-full;
  }

  /* link styles */
  .link-primary {
    @apply transition-all text-primary-500 hover:text-primary-700;
  }
  .link-black {
    @apply transition-all text-black hover:text-primary-500;
  }
  .link-white {
    @apply transition-all text-white hover:text-primary-500;
  }
  .link-contrast {
    @apply transition-all text-black hover:underline;
  }
  .link-contrast-underline {
    @apply link-contrast underline;
  }
}
